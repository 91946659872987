import {MenuCreateOptionInterface} from 'kernel/types/module'
class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  $t(args: string) {
    return this.vm.$t(args)
  }

  get() : {[key: string]: any} {
    return {
      admin: [
        {
          label: this.vm.$t('page.project'),
          icon: 'fa fa-wrench',
          create: (options: MenuCreateOptionInterface) => options.hasRole(['ROLE_PROJECT']),
          route: {
            name: 'project',
          },
        },
        {
          label: this.vm.$t('page.plan'),
          icon: 'fa fa-clipboard-list',
          create: (options: MenuCreateOptionInterface) => options.hasRole(['ROLE_PROJECT']),
          route: {
            name: 'plan',
          },
        }
      ],
    }
  }
}

export default () => (new menu()).get()
